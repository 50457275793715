<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'general.filters' | translate }}
  </div>
  <div class="days ion-margin-bottom ion-margin-top">
    <app-selected-day [currentDay]="startDayS()"
      id="startTs"
      (changed)="onDayChange($event, true)"></app-selected-day>
    <div>-</div>
    <app-selected-day [currentDay]="endDayS()"
      id="endTs"
      (changed)="onDayChange($event, false)"></app-selected-day>
  </div>
  <div>
    <app-select-site-button [selectedSite]="siteS()"
      [items]="sitesS()"
      (selected)="onSite($event)"></app-select-site-button>
  </div>
  @if (projects$ | async; as projects) {
    <div>
      <ion-button color="light"
        shape="round"
        (click)="selectProject(projects)">
        @if (projectS()) {
          <ion-label>
            {{ projectS().name }}
          </ion-label>
        } @else {
          <ion-label>
            {{ 'report.selectProject' | translate }}
          </ion-label>
        }
      </ion-button>
    </div>
  }
  @if (tags$ | async; as tags) {
    <div>
      <ion-button color="light"
        shape="round"
        (click)="selectTag(tags)">
        @if (tagS()) {
          <ion-label>
            {{ tagS().name }}
          </ion-label>
        } @else {
          <ion-label>
            {{ 'filter.tag' | translate }}
          </ion-label>
        }
      </ion-button>
    </div>
  }
  <div class="ion-margin-top">
    <ion-button color="light"
      (click)="apply()">
      {{ 'general.ok' | translate }}
    </ion-button>
  </div>
</app-main-dialog>