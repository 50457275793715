<div class="ion-margin-bottom">
  <app-segment-list [segmentValue]="navSegmentValue()"
    [segmentButtons]="navSegmentButtons"
    (segmentChanged)="navSegmentChanged($event)">
  </app-segment-list>
</div>
@switch (navSegmentValue()) {
  @case ('open') {
    <h3 class="flex flex-justify-between">
      {{ 'site.waitingProcessing' | translate }}
      <ion-button (click)="onFilters()">
        <ion-icon slot="icon-only"
          name="filter-outline"></ion-icon>
      </ion-button>
    </h3>
    @for (worker of workers$ | async; track worker.guid) {
      <ion-item button="true"
        lines="none">
        <ion-checkbox slot="start"
          [checked]="worker.selected"
          (ionChange)="onWorker($event, worker)"></ion-checkbox>
        <ion-avatar slot="start">
          @if (worker.image != null) {
            <img [src]="worker.image" />
          } @else {
            <ion-icon class="full"
              name="person-circle-outline"></ion-icon>
          }
        </ion-avatar>
        <ion-label>
          {{ worker.name }}
        </ion-label>
        <div>
          @if (worker.min) {
            {{ worker.min | timestamp:'d.M.yyyy' }} -
          }
          @if (worker.max) {
            {{ worker.max | timestamp:'d.M.yyyy' }}
          }
        </div>
        <div slot="end">
          {{ worker.hours | duration:true }}h
        </div>
      </ion-item>
    } @empty {
      <app-no-results></app-no-results>
    }
    <p>{{ 'timesheet.manageInfo' | translate }}</p>
    <div class="ion-margin-top">
      <ion-button color="light"
        [disabled]="selectedWorkers().length === 0"
        (click)="toManage()">
        {{ 'timesheet.manage' | translate }}
      </ion-button>
    </div>
  } @case ('done') {
    <h3 class="flex flex-justify-between">
      {{ 'sites.archivedList' | translate }}
      <ion-button (click)="onFilters()">
        <ion-icon slot="icon-only"
          name="filter-outline"></ion-icon>
      </ion-button>
    </h3>
    @for (worker of archiveWorkers$ | async; track worker.guid) {
      <ion-item color="primary"
        lines="none">
        <ion-avatar slot="start">
          @if (worker.image != null) {
            <img [src]="worker.image" />
          } @else {
            <ion-icon class="full"
              name="person-circle-outline"></ion-icon>
          }
        </ion-avatar>
        <ion-label>
          {{ worker.name }}
        </ion-label>
      </ion-item>
      @for (timesheet of worker.timesheets; track timesheet.guid) {
        <ion-item lines="none">
          @if (timesheet?.dateIn) {
            <span slot="start">
              {{ timesheet.dateIn | date:'dd.MM.yyyy' }}
            </span>
          }
          <div class="full-width">
            <div class="content">
              {{ timesheet.siteName }}
              <div>
                @if (timesheet?.dateIn) {
                  <span>
                    {{ timesheet.dateIn | date:'HH:mm' }}
                  </span>
                }
                <span> - </span>
                @if (timesheet?.dateOut) {
                  <span>
                    {{ timesheet.dateOut | date:'HH:mm' }}
                  </span>
                }
              </div>
            </div>
          </div>
          <div slot="end"
            class="summary">
            @if (timesheet?.dateIn && timesheet?.dateOut) {
              {{ (timesheet.dateOut.getTime() - timesheet.dateIn.getTime()) | duration }}
            }
          </div>
        </ion-item>
      }
    } @empty {
      <app-no-results></app-no-results>
    }
  }
}