@if ({
  days: days$ | async,
  times: times$ | async,
  }; as vmList) {
  <div class="calendar">
    <div class="flex days title">
      <div class="time"
        [class.only-one-day]="oneDayOnle()">
        <ion-icon name="time-outline"></ion-icon>
      </div>
      @for (day of vmList.days; track day) {
        <div class="day"
          [class.only-one-day]="oneDayOnle()">
          <div class="desktop">{{ 'general.weekdays.' + day.weekday | translate }}</div>
          <div class="mobile">{{ 'weekdays.' + day.weekday | translate }}</div>
          <div>{{ day.date | date:'dd.MM.' }}</div>
        </div>
      }
    </div>
    <ng-scrollbar visibility="hover">
      <div [id]="subId() + 'times'"
        class="times">
        @for (time of vmList.times; track time) {
          <div class="flex days"
            [id]="subId() + '' + (time.start | date:'HH.mm')">
            <div class="time"
              [class.only-one-day]="oneDayOnle()">
              {{ time.start | date:'HH.mm' }}
            </div>
            @for (day of vmList.days; track day) {
              <div class="day"
                [class.only-one-day]="oneDayOnle()">
                @if (getDayTime(time, day); as dayTime) {
                  @for (item of $any(dayTime).items; track item) {
                    <div class="item link flex-align-center"
                      [ngStyle]="{
                        height: item.height,
                        background: item.color,
                        width: item.width,
                        right: item.right,
                      }"
                      (click)="onUpdate(item)">
                      <div class="time-title">
                        {{ item.name }}
                        @if (item.subtitle) {
                          <div>
                            {{ item.subtitle }}
                          </div>
                        }
                        @if (item.information) {
                          <div>
                            <ion-note>
                              {{ item.information }}
                            </ion-note>
                          </div>
                        }
                        @if (item.tickets$ | async; as tickets) {
                          @if ($any(tickets).length > 0) {
                            <div class="ion-margin-top">
                              <ion-button class="tickets"
                                (click)="onTickets($event, item)">
                                {{ 'item.tasks' | translate:{ num: $any(tickets).length } }}
                              </ion-button>
                            </div>
                          }
                        }
                      </div>
                    </div>
                  } @empty {
                    @if (oneDayOnle()) {
                      <div class="empty-slot"
                        (click)="onItem(time)">
                        <ion-button fill="clear">
                          <ion-icon slot="icon-only"
                            name="add-circle-outline"></ion-icon>
                        </ion-button>
                      </div>
                    }
                  }
                }
              </div>
            }
          </div>
        }
      </div>
    </ng-scrollbar>
  </div>
}