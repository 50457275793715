import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { CalendarItem, WeekCalendarComponent } from 'src/app/components/calendar/calendar.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Schedule } from 'src/app/models/schedule.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { AddScheduleDialogComponent } from 'src/app/schedule/dialog/add-schedule/add-schedule.dialog';
import { UserTicketsTodayDialogComponent } from 'src/app/schedule/dialog/user-tickets/user-tickets.dialog';
import { AreasService } from 'src/app/services/areas.service';
import { AddScheduleItem, SchedulesService } from 'src/app/services/schedules.service';
import { SitesService } from 'src/app/services/sites.service';
import { getCalendarItem } from 'src/app/utility/item';
import { Roles } from 'src/app/utility/role';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule.dialog.html',
  styleUrls: ['./schedule.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    WeekCalendarComponent,
  ],
})
export class ScheduleDialogComponent {
  private areasService = inject(AreasService);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);
  private schedulesService = inject(SchedulesService);
  private sitesService = inject(SitesService);
  private translate = inject(TranslateService);

  schedulesS = signal<Schedule[]>([]);
  user = this.navParams.get<SubItem>('user');
  sitesS = computed(() => this.schedulesS().map((it) => this.sitesService.getSync(it.site)));
  date = computed(() => {
    if (this.schedulesS().length > 0) {
      return parse(this.schedulesS()[0].date, 'd.M.yyyy', new Date());
    }
  });

  items = computed(() => this.schedulesS().map((it) => {
    const item = getCalendarItem(it, this.user.guid, this.sitesS());
    item.tickets$ = this.areasService.getUserTicketsByDate(this.date(), this.user.guid, { guid: item.item.site }).pipe(shareReplay(1));
    return item;
  }));

  absence = computed(() => this.schedulesS().some((it) => it.absence));
  userTime = computed(() => this.schedulesS().find((it) => it.absence)?.times.find((it) => it.user === this.user?.guid));

  constructor() {
    const schedules = this.navParams.get<Schedule[]>('schedules');
    this.schedulesS.set(schedules);
  }

  private async updateSchedules(site: string, date: string, remove?: string) {
    const newSiteSchedule = await firstValueFrom(this.schedulesService.getSingle({ site, date }));
    this.schedulesS.update((items) => {
      const oldItems = items.filter((it) => it.guid !== newSiteSchedule.guid && it.guid !== remove);
      return [...oldItems, newSiteSchedule];
    });
  }


  private async remove(schedule: Schedule) {
    const user = this.navParams.get('user');
    schedule = this.schedulesService.removeUser(schedule, user.guid);
    return this.schedulesService.update(schedule);
  }

  onTicketList(calendarItem: CalendarItem<Schedule>) {
    const date = calendarItem.startDate;
    const site = this.sitesS().find((it) => it.guid === calendarItem.item.site);
    this.modalCtrl.create({ component: UserTicketsTodayDialogComponent, componentProps: { user: this.user, site, date } }).then((m) => {
      m.present();
    });
  }

  onUpdate(calendarItem: CalendarItem<Schedule>) {
    const date = calendarItem.startDate;
    const site = this.sitesS().find((it) => it.guid === calendarItem.item.site);
    this.modalCtrl.create({ component: AddScheduleDialogComponent, componentProps: { user: this.user, date, machines: [], useDateTimes: true, calendarItem, site } }).then((m) => {
      m.present();

      m.onDidDismiss<AddScheduleItem & { deleted: boolean }>().then(async (data) => {
        if (data.data) {
          if (data.data.deleted) {
            this.dismiss();
            return;
          }
          data.data.status = 'draft';
          if (site.guid !== data.data.site.guid || date.toLocaleDateString('fi') !== data.data.startDate.toLocaleDateString('fi')) {
            // Date or site changed
            const siteSchedule = await firstValueFrom(this.schedulesService.getSingle({ site: data.data.site?.guid ?? null, date: data.data.startDate.toLocaleDateString('fi') }));
            const oldSchedule = this.schedulesService.removeUser(calendarItem.item, this.user.guid);
            await this.schedulesService.update(oldSchedule);
            if (siteSchedule != null) {
              const schedule = this.schedulesService.updateSchedule(siteSchedule, data.data, this.user);
              await this.schedulesService.update(schedule);
            } else {
              await this.schedulesService.handleAddSchedule(data.data, { guid: this.user.guid, displayName: this.user.name, role: Roles.worker }, siteSchedule);
            }
            await this.updateSchedules(data.data.site?.guid ?? null, data.data.startDate.toLocaleDateString('fi'), oldSchedule.guid);
          } else {
            const schedule = this.schedulesService.updateSchedule(calendarItem.item, data.data, this.user);
            this.schedulesService.update(schedule);
            this.schedulesS.update((val) => {
              const old = val.filter((it) => it.guid !== schedule.guid);
              return [...old, schedule];
            });
          }
        }
      });
    });
  }

  onNew(date: Date) {
    this.modalCtrl.create({ component: AddScheduleDialogComponent, componentProps: { user: this.user, date, machines: [], useDateTimes: true } }).then((m) => {
      m.present();

      m.onDidDismiss<AddScheduleItem>().then(async (data) => {
        if (data.data) {
          const siteSchedule = await firstValueFrom(this.schedulesService.getSingle({ site: data.data.site?.guid ?? null, date: date.toLocaleDateString('fi') }));
          data.data.status = 'draft';
          this.schedulesService.handleAddSchedule(data.data, { guid: this.user.guid, displayName: this.user.name, role: Roles.worker }, siteSchedule).then(async () => {
            await this.updateSchedules(data.data.site?.guid ?? null, date.toLocaleDateString('fi'));
          });
        }
      });
    });
  }

  onDelete() {
    Dialog.confirm({
      message: `${this.translate.instant('general.delete') as string}?`,
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        // Delete
        const schedule = this.schedulesS().find((it) => it.absence);
        this.remove(schedule).then(() => this.dismiss());
      }
    });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
