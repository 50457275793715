@if ({
  items: items$ | async
}; as vm) {
  <div class="flex-justify-between">
    <div class="flex-align-center">
      @if (roleS() !== 'worker') {
        <ion-button color="secondary"
          (click)="onFilterTag('area')">
          @for (area of filterArea(); track $index) {
            <span class="tag"> {{ area.name | translate }}</span>
          } @empty {
            {{ 'shift.area' | translate }}
          }
        </ion-button>
        <ion-button color="secondary"
          (click)="onFilterTag('team')">
          @for (team of filterTeam(); track $index) {
            <span class="tag"> {{ team.name | translate }}</span>
          } @empty {
            {{ 'shift.team' | translate }}
          }
        </ion-button>
      }
      <ion-toggle color="secondary"
        [value]="showDetails"
        (ionChange)="showDetails.set($event.detail.checked)">
        {{ 'shift.showDetails' | translate }}
      </ion-toggle>
    </div>
    <app-week-switch (dateChanged)="onDateChange($event)"></app-week-switch>
    @if (hasDrafts()) {
      <div>
        <ion-button color="light"
          (click)="onPublish(vm.items)">
          {{ 'general.publish' | translate }}
        </ion-button>
      </div>
    }
  </div>
  <table>
    <tr class="info">
      <td>
      </td>
      @if (!mobileView()) {
        <td>
        </td>
        <td>
        </td>
      }
      @for (day of days(); track day.date) {
        <td>
          @if (day.isPeriodEnd) {
            <div>
              <div [title]="'users.levelingPeriodChanges' | translate">
                <ion-icon name="information-circle-outline"></ion-icon>
              </div>
            </div>
          }
        </td>
      }
    </tr>
    <tr class="header">
      <th>
        <div class="phase-name">
          {{ 'shift.person' | translate }}
        </div>
      </th>
      @if (!mobileView()) {
        <th>
          <div class="phase-name">
            {{ 'shift.team' | translate }}
          </div>
        </th>
        <th>
          <div class="phase-name">
            {{ 'shift.area' | translate }}
          </div>
        </th>
      }
      @for (day of days(); track day.date) {
        <th [class.weekend]="day.isWeekend"
          [class.period-start]="day.isPeriodStart"
          [class.period-end]="day.isPeriodEnd">
          <div class="item">
            {{ day.date | date:'d.M.' }}
          </div>
        </th>
      }
    </tr>
    @for (item of vm.items; track item.guid) {
      <tr>
        <td>
          <ion-button color="secondary"
            size="small"
            (click)="onUser(item)">
            <ion-label> {{ item.name }}
              @if (mobileView()) {
                <br /><ion-note>{{ item.teams }}</ion-note>
                <br /><ion-note>{{ item.areas }}</ion-note>
              }
            </ion-label>
          </ion-button>
        </td>
        @if (!mobileView()) {
          <td>
            <div class="phase-name"
              [title]="item.teams">
              {{ item.teams }}
            </div>
          </td>
          <td>
            <div class="phase-name"
              [title]="item.areas">
              {{ item.areas }}
            </div>
          </td>
        }
        @for (day of days(); track day.date) {
          @let schedule = getItem(day.dateString, item.guid, item.schedules);
          <td [class.absence]="schedule.absence"
            [class.has-item]="schedule.timeStatus === 'published'"
            [class.has-draft]="schedule.timeStatus === 'draft'"
            [class.full-hours]="item.fullHours"
            [class.weekend]="day.isWeekend"
            [class.period-start]="day.isPeriodStart"
            [class.period-end]="day.isPeriodEnd"
            [class.details]="showDetails()"
            class="link"
            (click)="onItem(day.date, item, item.schedules)">
            <div class="item">
              @if (showDetails()) {
            @for (it of schedule.details; track $index) {
              <div>
                {{ it.start | timestamp:'HH:mm' }} - {{ it.end | timestamp:'HH:mm' }} {{ it.site }}
              </div>
            }
          } @else {
            @if (schedule.hours) {
              <circle-progress [animation]="false"
                [percent]="schedule.percentage"
                [radius]="20"
                [outerStrokeWidth]="2"
                [innerStrokeWidth]="0"
                [showSubtitle]="false"
                [title]="schedule.hours.hours + ',' + schedule.hours.minutes"
                [titleFontSize]="'0.6em'"
                [titleColor]="'#fff'"
                [unitsColor]="'#fff'"
                [units]="'h'">
              </circle-progress>
            }
          }
          </div>
          </td>
        }
      </tr>
    } @empty {
      <div class="ion-margin-top">
        <app-no-results></app-no-results>
      </div>
    }
  </table>
}