import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { format } from 'date-fns';
import { filter, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectSiteButtonComponent } from 'src/app/components/select-site/select-site.component';
import { SelectedDayComponent } from 'src/app/day/selected-day/selected-day.component';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { Tag } from 'src/app/models/tag.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { TagsService } from 'src/app/services/tags.service';
import { selectedDayFormat } from 'src/app/utility/time';

@Component({
  selector: 'app-filter-timesheets-dialog',
  templateUrl: './filter-timesheets.dialog.html',
  styleUrls: ['./filter-timesheets.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    SelectedDayComponent,
    SelectSiteButtonComponent,
  ],
})
export class TimesheetsFilterDialogComponent {
  private contractorsService = inject(ContractorsService);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);
  private siteProjectsService = inject(SiteProjectsService);
  private tagsService = inject(TagsService);

  // Date range
  startDayS = signal(this.navParams.get<string>('startDay'));
  endDayS = signal(this.navParams.get<string>('endDay'));
  // Tags
  tagS = signal<Tag>(this.navParams.get('tag'));
  // Site
  siteS = signal(this.navParams.get<Site>('site'));
  sitesS = signal(this.navParams.get<Site[]>('sites'));
  // Project
  projectS = signal(this.navParams.get<SiteProject>('project'));

  projects$ = toObservable(this.siteS).pipe(
    filter((site) => site != null),
    switchMap((site) => this.siteProjectsService.getList({ site: site.guid, contractor: this.contractorsService.contractorS()?.guid })),
    shareReplay(1),
  );

  tags$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.tagsService.getList({ contractor: contractor.guid })),
    shareReplay(1),
  );

  onDayChange(newdate: Date, start: boolean) {
    if (start) {
      this.startDayS.set(format(newdate, selectedDayFormat));
    } else {
      this.endDayS.set(format(newdate, selectedDayFormat));
    }
  }

  onSite(site: Site) {
    this.siteS.set(site);
    if (site == null) {
      this.projectS.set(null);
    }
  }

  selectProject(items: SiteProject[]) {
    const clearButton = this.projectS() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.projectS.set(null);
          } else {
            this.projectS.set(data.data);
          }
        }
      });
    });
  }

  selectTag(items: Tag[]) {
    const clearButton = this.tagS() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Tag>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.tagS.set(null);
          } else {
            this.tagS.set(data.data);
          }
        }
      });
    });
  }

  apply() {
    this._modal.dismiss({ start: this.startDayS(), end: this.endDayS(), site: this.siteS(), project: this.projectS(), tag: this.tagS() });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
