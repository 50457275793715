import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core';
import { addYears, format, startOfToday } from 'date-fns';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { parse } from 'date-fns/parse';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { Tag } from 'src/app/models/tag.model';
import { DurationPipe } from 'src/app/pipes/duration/duration.pipe';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { NavigationService } from 'src/app/services/navigation.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { fieldSorter } from 'src/app/utility/sort';
import { calcDuration, selectedDayFormat } from '../../utility/time';
import { TimesheetsFilterDialogComponent } from './dialog/filter-timesheets.dialog';

interface TsWorker {
  guid: string;
  image: string;
  min: Date;
  max: Date;
  hours: number;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
    DurationPipe,
    NoResultsComponent,
    TimestampPipe,
    SegmentListComponent,
  ],
})
export class TimesheetsComponent {
  private navigationService = inject(NavigationService);

  private startDate$ = new BehaviorSubject<Date>(addYears(startOfToday(), -5));
  private endDate$ = new BehaviorSubject<Date>(startOfToday());
  private filterSite$ = new BehaviorSubject<Site>(null);
  private filterProject$ = new BehaviorSubject<SiteProject>(null);
  private filterTag$ = new BehaviorSubject<Tag>(null);
  private sites$: BehaviorSubject<Site[]> = new BehaviorSubject([]);
  private users$ = this.usersService.getList();

  navSegmentValue = signal('open');

  readonly navSegmentButtons = [
    {
      value: 'open',
      name: 'tickets.open',
      show: true,
    },
    {
      value: 'done',
      name: 'sites.archivedList',
      show: true,
    },
  ];

  workers$ = combineLatest([
    this.startDate$.asObservable(), this.endDate$.asObservable(),
    this.usersService.getCurrentUser(), this.sitesService.isSiteLead$,
    this.filterSite$.asObservable(), this.filterProject$.asObservable(),
  ]).pipe(
    filter(([startDate, endDate, user]) => !!startDate && !!endDate && user != null),
    switchMap(([startDate, endDate, user, isSitelead, filterSite, filterProject]) => combineLatest(
      [
        this.timesheetsService.getList({
          startDate,
          endDate,
          checked: false,
          contractor: user.contractors[0],
          site: filterSite?.guid,
          sites: filterSite == null ? isSitelead ? this.sitesService.getSiteleadSites().map((s) => s.guid) : undefined : undefined,
          project: filterProject?.guid,
        }),
        this.users$,
        this.sites$.asObservable(),
        this.filterTag$.asObservable(),
      ]),
    ),
    map(([timesheets, users, sites, filterTag]) => {
      timesheets = timesheets.filter((ts) => sites.map((s) => s.guid).includes(ts.site));
      const workers = Array.from<TsWorker>([]);
      timesheets.filter((it) => filterTag == null || filterTag.users.some((itt) => itt.guid === it.user)).map((timesheet) => {
        const user = users.find((u) => u.guid === timesheet.user);
        const old = workers.find((w) => w.guid === user.guid);
        timesheet.username = timesheet.username ?? user?.displayName;
        if (old) {
          old.hours += calcDuration(timesheet.dateIn, timesheet.dateOut);
          if (isBefore(timesheet.dateIn, old.min)) {
            old.min = timesheet.dateIn;
          }
          if (isAfter(timesheet.dateOut, old.max)) {
            old.max = timesheet.dateOut;
          }
        } else {
          workers.push({
            guid: user.guid,
            image: user.image,
            min: timesheet.dateIn,
            max: timesheet.dateOut,
            hours: calcDuration(timesheet.dateIn, timesheet.dateOut),
            name: user.displayName,
            selected: false,
          });
        }
      });
      return workers.sort(fieldSorter(['name']));
    }),
    shareReplay(1),
  );

  archiveWorkers$ = combineLatest([
    this.startDate$.asObservable(), this.endDate$.asObservable(),
    this.usersService.getCurrentUser(), this.sitesService.isSiteLead$,
    this.filterSite$.asObservable(), this.filterProject$.asObservable(),
  ]).pipe(
    filter(([startDate, endDate, user]) => !!startDate && !!endDate && user != null),
    switchMap(([startDate, endDate, user, isSitelead, filterSite, filterProject]) => combineLatest(
      [
        this.timesheetsService.getList({
          startDate,
          endDate,
          checked: true,
          contractor: user.contractors[0],
          site: filterSite?.guid,
          sites: filterSite == null ? isSitelead ? this.sitesService.getSiteleadSites().map((s) => s.guid) : undefined : undefined,
          project: filterProject?.guid,
        }),
        this.users$,
        this.sites$.asObservable(),
        this.filterTag$.asObservable(),
      ]),
    ),
    map(([timesheets, users, sites, filterTag]) => {
      timesheets = timesheets.filter((ts) => sites.map((s) => s.guid).includes(ts.site));
      const workers = Array.from([]);
      timesheets.filter((it) => filterTag == null || filterTag.users.some((itt) => itt.guid === it.user)).map((timesheet) => {
        const user = users.find((u) => u.guid === timesheet.user);
        const old = workers.find((w) => w.guid === user.guid);
        timesheet.username = timesheet.username ?? user?.displayName;
        if (old) {
          old.timesheets.push(timesheet);
        } else {
          workers.push({
            guid: user.guid,
            image: user.image,
            name: user.displayName,
            timesheets: [timesheet],
          });
        }
      });
      return workers.sort(fieldSorter(['name']));
    }),
    shareReplay(1),
  );

  selectedWorkers = signal<TsWorker[]>([]);

  constructor(
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private timesheetsService: TimesheetsService,
    private usersService: UsersService,
  ) {
    combineLatest([this.sitesService.getAdminList(), this.usersService.getCurrentUser()]).pipe(
      filter(([sites, user]) => sites != null && user != null),
      map(([sites, user]) => {
        const ownSites = sites.filter((site) => [Roles.admin].includes(user.role) || site.supervisor?.some((u) => u.guid === user.guid) || this.usersService.isSitelead(site));
        return ownSites;
      }),
      take(1),
      takeUntilDestroyed(),
    ).subscribe((sites) => {
      this.sites$.next(sites);
    });
  }


  private onDayChange(newdate: Date, start: boolean) {
    if (start) {
      this.startDate$.next(newdate);
    } else {
      this.endDate$.next(newdate);
    }
  }

  navSegmentChanged(value: string) {
    if (value) {
      this.navSegmentValue.set(value);
    }
  }

  onWorker(event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<boolean>>, worker: TsWorker) {
    const checked = event.detail.checked;
    worker.selected = checked;
    if (worker.selected) {
      this.selectedWorkers.update((val) => [...val, worker]);
    } else {
      this.selectedWorkers.update((val) => val.filter((it) => it.guid !== worker.guid));
    }
  }

  onFilters() {
    this.modalCtrl.create({
      component: TimesheetsFilterDialogComponent,
      componentProps: {
        startDay: format(this.startDate$.getValue(), selectedDayFormat),
        endDay: format(this.endDate$.getValue(), selectedDayFormat),
        site: this.filterSite$.getValue(),
        sites: this.sites$.getValue(),
        project: this.filterProject$.getValue(),
        tag: this.filterTag$.getValue(),
      },
    }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.start) {
            const start = parse(data.data.start, selectedDayFormat, new Date());
            this.onDayChange(start, true);
          }
          if (data.data.end) {
            const end = parse(data.data.end, selectedDayFormat, new Date());
            this.onDayChange(end, false);
          }
          if (data.data.site) {
            this.filterSite$.next(data.data.site);
          } else {
            this.filterSite$.next(null);
          }
          if (data.data.project) {
            this.filterProject$.next(data.data.project);
          } else {
            this.filterProject$.next(null);
          }
          if (data.data.tag) {
            this.filterTag$.next(data.data.tag);
          } else {
            this.filterTag$.next(null);
          }
          this.selectedWorkers.set([]);
        }
      });
    });
  }

  toManage() {
    const users = this.selectedWorkers();
    const filters = {
      users: users.map((it) => it.guid),
      site: this.filterSite$.getValue()?.guid,
      project: this.filterProject$.getValue()?.guid,
      startDate: this.startDate$.getValue(),
      endDate: this.endDate$.getValue(),
    };
    this.timesheetsService.timesheetFilters.set(filters);
    this.navigationService.navigate(['/process-timesheets']);
  }
}
