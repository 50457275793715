<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ user?.name }}
  </div>
  @if (absence()) {
    <h4> {{ 'timesheet.absences.' + userTime().absence | translate }}</h4>
    <div class="ion-margin-top">
      <ion-button color="danger"
        shape="round"
        (click)="onDelete()">
        {{ 'general.delete' | translate }}
      </ion-button>
    </div>
  } @else {
    <app-week-calendar [items]="items()"
      [selectedDate]="date()"
      [calendarId]="user?.guid"
      (update)="onUpdate($event)"
      (tickets)="onTicketList($event)"
      (itemClicked)="onNew($event)"></app-week-calendar>
  }
</app-main-dialog>